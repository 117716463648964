import React from 'react';
import { FC, memo } from 'react';

type Props = {};

export const CardSection: FC<Props> = memo(({ children }) => {
	return <div className="c-cards">{children}</div>;
});

CardSection.displayName = 'CardSection';
