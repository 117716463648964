import React from 'react';
import { FC, memo, Children } from 'react';

type Props = {
	//display?: string;
};

export const CardList: FC<Props> = memo(({ children }) => {
	return <div className="c-cards__list grid grid--large">{children}</div>;
});

CardList.displayName = 'CardList';
