import React from 'react';
import { CardSection } from '../components/ui/card/CardSection';
import { CardList } from '../components/ui/card/CardList';
import loadable from '@loadable/component';
import { Layout } from '../components/ui/Layout';

const Helmet = loadable(() => import('react-helmet'));
const Header = loadable(() => import('../components/layout/Header'));
const AddressForm = loadable(() => import('../components/organisms/AddressForm'));
const Banner = loadable(() => import('../components/ui/banner/Banner'));

const cardItems = [
	{
		id: 98,
		img: '/img/pozice.svg',
		width: 60,
		height: 60,
		altText: 'Obrázek Vyšší pozice ve vyhledávání',
		title: 'Vyšší pozice ve vyhledávání',
		text: 'Rychlost zlepší pozice PPC inzerce a má vliv i na umístění v přirozených výsledcích vyhledávání.',
	},
	{
		id: 97,
		img: '/img/konverze.svg',
		width: 60,
		height: 60,
		altText: 'Obrázek Zlepšení konverze',
		title: 'Zlepšení konverze',
		text: 'Každá vteřina zrychlení načtení webu se vrátí v podobě růstu byznysových metrik webu.',
	},
	{
		id: 99,
		img: '/img/zisk.svg',
		width: 60,
		height: 60,
		altText: 'Obrázek Zvýšení zisků',
		title: 'Zvýšení zisků',
		text:
			'Rychlé weby mají vyšší konverze, více návštěvníků a je prokázáno, že podporují pozitivní vnímání značky.',
	},
];

const listItems = cardItems.map(item => (
	<div key={item.id} className="c-cards__item grid__cell size--4-12@md">
		<article className="b-card">
			<div className="b-card__img">
				<img data-src={item.img} width={item.width} height={item.height} alt="" className="lazyload" />
			</div>
			<h3 className="b-card__title">{item.title}</h3>
			<div className="b-card__text">
				<p>{item.text}</p>
			</div>
		</article>
	</div>
));

export default () => (
	<Layout>
		<Helmet>
			<title>PageSpeed.cz - Když na rychlosti záleží</title>
		</Helmet>
		<Header showText />
		<AddressForm />
		<section className="s-audit">
			<div className="row-main">
				<CardSection>
					<h2 className="c-cards__title u-color-blue u-text-center u-mb-50 u-mb-100-lg">
						<span className="u-color-blue-light">Rychlé stránky</span> lépe plní své cíle.
						<small className="u-block">Patří k nim i&nbsp;ta vaše?</small>
					</h2>
					<CardList>{listItems}</CardList>
				</CardSection>
			</div>
		</section>
		<Banner showImage />
	</Layout>
);
